// directives/v-track-element.js
import { useElementTracker } from '../composables/useElementTracker';

const tracker = useElementTracker();

export default {
  mounted(el, binding) {
    tracker.addElement(el, binding.value);
  },
  unmounted(el) {
    tracker.removeElement(el);
  },
};

export { tracker }; // Export the tracker for reactive access elsewhere
