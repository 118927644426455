import { ref, computed } from 'vue';
import { useMouse, useScroll } from '@vueuse/core'

const { x, y } = useMouse();
const {x: scrollX, y: scrollY} = useScroll(window, { throttle: 10 });
const standstillScroll = ref(0);
const standstill = ref(false);
const lastStadingPoint = ref(0);

watch([scrollY, y], (newObject, oldObject) => {
  if(!standstill.value) lastStadingPoint.value = scrollY.value;
  standstill.value = (newObject[1] == oldObject[1]);
  standstillScroll.value = (y.value - lastStadingPoint.value);
});

const screenX = computed(() => x.value - scrollX.value);
const screenY = computed(() => standstill.value ? standstillScroll.value : y.value - scrollY.value);

export function useScreenCursor() {
  return { screenX, screenY };
}
