import { tracker } from '@/directives/v-scene'
const trackedElements = tracker.topTwoScenes; // Access reactive data

export const interpolateDistortions = (a, b, blend) => {
  return {
    x: a.x * (1 - blend) + b.x * blend,
    y: a.y * (1 - blend) + b.y * blend
  };
};

export const  rotatePoint = (point, width, height, time, speed = 0.01) => {
  const cx = width / 2;
  const cy = height / 2;

  const dx = point.x - cx;
  const dy = point.y - cy;

  const theta = time * speed * point.x/width; // Angle in radians

  const rotatedX = dx * Math.cos(theta) - dy * Math.sin(theta) + cx;
  const rotatedY = dx * Math.sin(theta) + dy * Math.cos(theta) + cy;

  return { x: rotatedX, y: rotatedY };
}

export const distort = (x, y, width, height, time = 1, blend = 0.5, soundSpectrum = [0,0,0,0]) => {
  const soundX = soundDistort(x / width, width, y / height, height, soundSpectrum).x;
  const soundY = soundDistort(x / width, width, y / height, height, soundSpectrum).y;
  const distorts = [];
  distorts[0] = {x: soundX,y: soundY};
  distorts[1] = distortA(soundX, soundY, width, height, 2000)
  distorts[2] = distortA(soundX, soundY, width, height, 2000, 0.5, 0.9);
  distorts[3] = distortA(soundX, soundY, width, height, 0.5);
  distorts[4] = interpolateDistortions(distorts[0], distorts[2], 0.66);
  distorts[5] = distortC(soundX, soundY, width, height);
  distorts[6] = distortB(soundX, soundY, width, height);
  distorts[7] = interpolateDistortions(distorts[0], distorts[1], 0.4);
  return interpolateDistortions(distorts[(trackedElements.value[0]?.id) || 0], distorts[(trackedElements.value[1]?.id) || 0], 1 - ((trackedElements.value[0]?.scrollPercent || 100) / 100));
};

export const soundDistort = (normalx, width, normaly, height, soundSpectrum) => {
  // Ensure soundSpectrum is an array with fallback
  const spectrum = Array.isArray(soundSpectrum) ? soundSpectrum : [0, 0, 0, 0];

  // Calculate distance from center (0.5, 0.5)
  const centerDistance = Math.sqrt(
    Math.pow(normalx - 0.5, 2) + Math.pow(normaly - 0.5, 2)
  );

  // Calculate distortion vector away from center
  const angle = Math.atan2(normaly - 0.5, normalx - 0.5);
  const band = Math.round(centerDistance * spectrum.length);
  const loudness = spectrum[band] / 100 * 1 - centerDistance;
  const distortionMagnitude = centerDistance * loudness;

  return {
    x: (normalx * width) + Math.cos(angle) * distortionMagnitude * width,
    y: (normaly * height) + Math.sin(angle) * distortionMagnitude * height
  };
}

export const distortA = (x, y, width, height, time = 1, inside = 0.5, cycleSize = 0.5) => {
  if (x && y) {
    const pushFactor = 2;

    // Unified scaling reference to keep a circular shape
    const refSize = Math.max(width, height);

    // Calculate offsets to center the circle
    const offsetX = (width - refSize) / 2;
    const offsetY = (height - refSize) / 2;

    // Normalize coordinates using offsets
    const normalx = (x - offsetX) / refSize;
    const normaly = (y - offsetY) / refSize;

    const centerX = 0.5;
    const centerY = 0.5;

    const dx = normalx - centerX;
    const dy = normaly - centerY;
    const dd = Math.sqrt(dx * dx + dy * dy);
    const cycle = Math.sin(3000 * -0.05 * dd) * cycleSize;
    const distDelta = inside + 0.075 * cycle - dd;

    const bentNormalX = (dx / dd) * distDelta;
    const bentNormalY = (dy / dd) * distDelta;

    // Scale back to original dimensions and re-apply offsets
    return {
      x: x + bentNormalX * refSize,
      y: y + bentNormalY * refSize
    };
  } else {
    return { x, y };
  }
};

export const distortRainbow = (x, y, inside = 0.5, edge = 0.5) => ({
  x: (0.5 + (edge - inside * y) * Math.cos(Math.PI * (1 - x))) - x,
  y: (0.5 - (edge - inside * y) * Math.sin(Math.PI * (1 - x))) - y
});

export const distortB = (x, y, width, height) => {
  if (x && y) {
    const normalX = x / width;
    const normalY = y / height;
    const distortXY = {
      x: distortRainbow(normalX, normalY).x * width,
      y: distortRainbow(normalX, normalY).y * height
    };
    return { x: x + distortXY.x, y: y + distortXY.y };
  } else {
    return { x, y };
  }
};

export const distortC = (x, y, width, height) => {
  if (x !== undefined && y !== undefined) {
    const centerX = width / 2; // Horizontal center
    const centerY = height / 2; // Vertical center
    const ratio = width / height;
    const offset = 10;

    let newX = x;
    let newY = y;

    const randomOffset = (value, maxOffset = 200) => Math.random() * maxOffset - maxOffset / 2;

    if (x <= y * ratio && x <= width - y * ratio) {
      newX = x / 10 + offset;
      newY = y + x / 4;
    } else if (y * ratio <= x && width - y * ratio <= x) {
      newX = width - (width - x) / 10 - offset;
      newY = y + (width - x) / 4;
    } else if (y <= centerY) {
      newY = y / 10 + offset;
      newX = x + y / 4;
    } else if (y >= centerY) {
      newY = height - (height - y) / 10 - offset;
      newX = x + y / 4;
    }

    return { x: newX, y: newY};
  } else {
    return { x, y };
  }
};


export const distortG = (x, y, width, height) => {
  if (x !== undefined && y !== undefined) {
    const centerX = width / 2;
    const centerY = height / 2;

    // Horizontal shift
    const distanceFromCenterX = Math.abs(x - centerX);
    const directionX = x < centerX ? -1 : 1;
    const shiftAmountX = (centerX - distanceFromCenterX) * 0.5 * directionX;

    // Vertical shift
    const distanceFromCenterY = Math.abs(y - centerY);
    const directionY = y < centerY ? -1 : 1;
    const shiftAmountY = (centerY - distanceFromCenterY) * 0.5 * directionY;

    return {
      x: x + shiftAmountX,
      y: y + shiftAmountY,
    };
  } else {
    return { x, y };
  }
};
