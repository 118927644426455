import { reactive, watch } from 'vue';
import { useIntersectionObserver, useWindowScroll, useWindowSize, useElementBounding } from '@vueuse/core';

export function useElementTracker() {
  const trackedElements = reactive([]);

  const addElement = (el, id) => {
    if (!trackedElements.find((item) => item.el === el)) {
      const bounding = useElementBounding(el);
      const { y: scrollY } = useWindowScroll();
      const { height: windowHeight } = useWindowSize();

      const elementData = reactive({
        id: id,
        inView: false,
        scrollPercent: 0, // Scroll visibility percentage relative to viewport
        visiblePixels: 0, // Number of pixels visible within the viewport
      });

      //Add to composable
      trackedElements.push(elementData);

      // Observe element visibility
      useIntersectionObserver( el, ([entry]) => { elementData.inView = entry.isIntersecting },{ threshold: [0, 0.5, 1] });

      // Initial calculation of visible pixels
      updateVisiblePixels(elementData, bounding, windowHeight);

      // Observe scroll progress
      watch(scrollY, () => updateVisiblePixels(elementData, bounding, windowHeight));
    }
  };

  const updateVisiblePixels = (elementData, bounding, windowHeight) => {
    const visibleHeight = Math.min(bounding.bottom.value, windowHeight.value) - Math.max(bounding.top.value, 0);
    elementData.visiblePixels = Math.max(visibleHeight, 0);
    elementData.scrollPercent = Math.max((visibleHeight / windowHeight.value) * 100, 0);
  };

  const removeElement = (el) => {
    const index = trackedElements.findIndex((item) => item.el === el);
    if (index !== -1) {
      trackedElements.splice(index, 1);
    }
  };

  // Computed property to find the two first elements that are in view
  const topTwoScenes = computed(() => {
    return trackedElements
      .filter((item) => item.inView) // Only include elements that are in view
      .slice(0, 2); // Take the first two elements
  });
  return {
    trackedElements,
    topTwoScenes,
    addElement,
    removeElement,
  };
}
