import { distort } from './utils/distortion'; // Import distortion utility function
import { composables } from '@/composables'
const soundData = composables().soundDataStore;

class Particle {
  constructor({ x, y, radius = 1.25, width = 1920, height = 1080 }) {
    this.x = distort(x, y, width, height).x;
    this.y = distort(x, y, width, height).y;
    this.width = width;
    this.height = height;
    this.ax = 0;
    this.ay = 0;
    this.vx = 0;
    this.vy = 0;
    this.ix = x;
    this.iy = y;
    this.radius = radius;
    this.baseRadius = radius; // Store the initial base radius
    this.maxScale = 10; // Maximum scaling factor
    this.minDist = width * 0.05; // Minimum distance for push force
    this.pushFactor = 0.5; // Push force multiplier
    this.pullFactor = 0.1; // Pull force multiplier
    this.dampFactor = 0.8; // Damping factor for velocity
    this.cursorDistance = 0;
  }

  // Apply the pull force towards the initial position
  applyPullForce(blend, time, soundSpectrum) {
    const dx = distort(this.ix, this.iy, this.width, this.height, time, blend, soundSpectrum).x - this.x;
    const dy = distort(this.ix, this.iy, this.width, this.height, time, blend, soundSpectrum).y - this.y;
    this.ax = dx * this.pullFactor;
    this.ay = dy * this.pullFactor;
  }

  // Apply the push force based on cursor position
  applyPushForce(cursor) {
    const dx = this.x - cursor.x.value;
    const dy = this.y - cursor.y.value;
    this.cursorDistance = Math.sqrt(dx * dx + dy * dy);
    const distDelta = this.minDist - this.cursorDistance;
    if (this.cursorDistance < this.minDist) {
      this.ax += (dx / this.cursorDistance) * distDelta * this.pushFactor;
      this.ay += (dy / this.cursorDistance) * distDelta * this.pushFactor;
    }
    //this.pullFactor = 0.01 + 0.1 * this.cursorDistance / this.minDist;
  }

  // Update particle's velocity
  updateVelocity() {
    this.vx += this.ax;
    this.vy += this.ay;
    this.vx *= this.dampFactor;
    this.vy *= this.dampFactor;
  }

  updateDistort(blend, time) {
    this.x = distort(this.ix, this.iy, this.width, this.height, 0, blend, time).x;
    this.y = distort(this.ix, this.iy, this.width, this.height, 0, blend, time).y;
  }

  updateHover() {
    this.x = this.x + this.vx;
    this.y = this.y + this.vy;
  }

  // Adjust the radius based on velocity
  adjustRadius() {
    const velocityMag = Math.sqrt(this.vx * this.vx + this.vy * this.vy);
    const scale = Math.min(this.maxScale, 1 + velocityMag * 0.1);
    this.radius = this.baseRadius * scale;
  }

  // Update the particle's position and radius
  update(cursor, blend, time, soundSpectrum) {
    //this.updateDistort(blend, time);
    this.applyPullForce(blend, time, soundSpectrum);
    this.applyPushForce(cursor);
    this.updateVelocity();
    this.updateHover();
    this.adjustRadius();
  }

  // Draw the particle on the canvas
  draw(context) {
    context.save();
    context.translate(this.x, this.y);
    context.beginPath();
    context.fillStyle = 'gray';
    context.arc(0, 0, this.radius, 0, Math.PI * 2);
    context.fill();
    context.restore();
  }

  // New method to update and draw the particle
  updateAndDraw(cursor, context, blend, time = 1) {
    const soundSpectrum = soundData.value?.length ? soundData.value : [0,0,0];
    this.update(cursor, blend, time, soundSpectrum);
    this.draw(context);
  }
}

export default Particle;
