//YAML Helpers
import yaml from 'js-yaml';
import { isProxy, toRaw } from 'vue';
import { defineStore } from 'pinia';

export const yamlToArray= (string) => {
  try {
    return Array.isArray(yaml.load(string)) ? yaml.load(string) : [yaml.load(string)];
  } catch (error) {
    console.error('String will not work', string)
    return []; // or you could return an empty object or any other fallback value
  }
};

export const averageArrayValuesToGroups = (array, groupSize) =>
  Array.from({ length: Math.ceil(array.length / groupSize) }, (skip, i) => 
    array.slice(i * groupSize, i * groupSize + groupSize).reduce((sum, num) => sum + num, 0) / groupSize
  );

export const clamp = (array, max) => array.slice(0, max);

export const filterItems = (array, predicate) => array.filter(predicate);

export const yamlToObject = (string) => {
  try {
    return yaml.load(string);
  } catch (error) {
    console.error('String will not work', string)
    return false; // or you could return an empty object or any other fallback value
  }
};

export const routeStore = defineStore('routes', () => {
  const route = useRoute();
  const visitedURLs = ref([])
  watch(route, (route) => visitedURLs.value.unshift(route.path));
  return {route, visitedURLs};
});

export const yamlFirst = (string) => yaml.load(string)[0].trim();

//JSON Helpers
export const cleanUpJSON = (input) => {return JSON.parse(JSON.stringify(input))};

export const objectToArray = (proxyObject) => {return toRaw(proxyObject)};


//URL to PATH
export const pathName = function(input) {
  let url = input ? new URL(input) : { pathname: '#' };
  return url?.pathname;
}

//Get alternative language
export const alternativeLanguage = function(i) {
  const linkElementForAlternate = document.head.querySelector('link[rel=alternate]');
  return linkElementForAlternate ? linkElementForAlternate.attributes.hreflang.value : false;
}

//Get image from files object by id
export const imageById = function(images, filename) {
  filename = filename.includes('/') ? filename.split('/').pop() : filename;
  const imageObject = images.filter( image => (image.filename == filename))[0];
  if(images){
    return objectToArray(imageObject);
  } else {
    console.error('No images');
  }
}

//URL to PATH
export const files = function(filesObject, by = 'filename') {
  let filesByFileName = {};
  const keys = filesObject ? Object.keys(filesObject) : [];
  for(const key of keys) {
    filesByFileName[filesObject[key][by]] = filesObject[key]
  };
  const files = JSON.parse(JSON.stringify(toRaw(filesByFileName)));
  return files
}

export const crossOriginSRCSET = (srcset) => {
  // Split the srcset into an array of URL and descriptor pairs
  const srcsetParts = srcset.split(', ');

  // Map each part to a relative URL
  const relativeSrcset = srcsetParts.map(part => {
    // Split part into URL and descriptor
    const [url, descriptor] = part.split(' ');
    // Create a URL object to easily extract the pathname
    const urlObj = new URL(url);
    // Rebuild the part with the relative path and descriptor
    return `${urlObj.pathname} ${descriptor}`;
  });

  // Join the parts back into a srcset string
  return relativeSrcset.join(', ');
}

export const crossOriginSrc = (src) => {
  // Create a URL object to easily extract the pathname
  const urlObj = new URL(src);

  // Return just the relative pathname
  return urlObj.pathname;
};

//Local images
export const srcImage = ((url) => {
  return new URL(`/src/${url}`, import.meta.url).href;
});

//Local images
export const publicImage = ((url) => {
  return new URL(`/src/${url}`, import.meta.url).href;
});

export const cleanString = (string) => {
  console.log(string);
  return string;
};

export const cleanArrayItems = (array) => {
  return array.map(function(item){return item.trim()});
};


//Get page object from path
export const pageFromPath = (path, pages) => {
  if (path, pages) {
    var slugs = path.trim().split('/');
    var page = false;
    var children = pages;
    while (slugs.length) {
      var id = slugs.shift();
      page = children.find ? children.find(o => o.uid === id) : false;
      if (page)
        children = typeof page.children === 'object' ? page.children : [];
    }
    if (page) {
      return page;
    } else {
      //console.error('No page!', path, pages);
    }
  } else {
    console.error('No pages! Add a page element with function', path, pages);
  }
}

//Get page objects with list of paths
export const pagesFromPaths = function(list, pages) {
  if(list !='undefined' && pages.length) {
    let self = this;
    let paths = (typeof list == 'array') ? list : Object.values(list);
    let returnArray = [];
    paths.map(function(path){
      let page = pageFromPath(path, pages);
      if(page) returnArray.push(page);
    });
    //console.log( Array.isArray(returnArray));
    return returnArray;
  }
}

//Seconds to minutes
export const secondsToDigitalDisplay = (seconds) => {
  var minutes=0;
  var output = '00:00';
  if(seconds > 0) output = ((minutes = ((seconds-(seconds%=60))/60)) < 9 ? '0'+ minutes : minutes)+(9<seconds?':':':0')+Math.round(seconds);
  return output
}

export const smoothStep = (edge0, edge1, x) => {
  const t = Math.max(0, Math.min(1, (x - edge0) / (edge1 - edge0)));
  return t * t * (3 - 2 * t);
}
